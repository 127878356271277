<template>
  <div
    id="contents"
    class="status_board_sanchaeTown"
    :class="{
      process_progress_status: tabIndex == 0,
      status_board_sanchaeTown_theme: tabIndex != 0,
      request_order_status: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToStatusMonitorPage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            v-if="tabIndex == 0"
            class="btn_admin"
            @click="FETCH_PRODUCTION_LIST"
          >
            새로고침 {{ timer }}
          </button>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <ProcessStatusForm
        v-if="tabIndex == 0"
        :key="key"
        @changetimer="changeTimer($event)"
      />
      <FinishedStockStatusForm v-if="tabIndex == 1" />
      <RequestOrderStatusForm v-if="tabIndex == 2" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ProcessStatusForm from '@/views/forms/Process/ProcessStatus/ProcessStatusForm';
import FinishedStockStatusForm from '@/views/forms/Custom/Monitor/23/FinishedStockStatusForm';
import RequestOrderStatusForm from '@/views/forms/Custom/Monitor/23/RequestOrderStatusForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    ProcessStatusForm,
    FinishedStockStatusForm,
    RequestOrderStatusForm,
  },
  data() {
    return {
      swiper: null,
      key: 0,
      timer: 0,
      asideIndex: [
        { title: '공정진행 현황', name: 'process_status' },
        { title: '완제품 재고현황', name: 'finished_stock_status' },
        { title: '출고지시 현황', name: 'request_order_status' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToStatusMonitorPage',
    }),
    changeTimer(e) {
      this.timer = e;
    },
    async FETCH_PRODUCTION_LIST() {
      await this.$store
        .dispatch('FETCH_PRODUCTION_LIST')
        .then(() => {
          this.key += 1;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '공정 진행현황을 불러오는 중 오류 발생',
          );
        });
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitStatusMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'sanchaeTown_monitoring';
</style>
