<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span>조회시간: {{ str_time }}</span>
          <button
            type="button"
            class="btn_sub1 refresh_btn"
            @click="refreshData"
          >
            재조회
          </button>
          <button class="btn_sub1" @click="fullScreen = !fullScreen">
            {{ fullScreen ? '닫기' : '전체화면' }}
          </button>
        </div>
        <div>
          <h5 class="title">출고지시 현황</h5>
        </div>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>매출처</th>
              <th>제품명</th>
              <th>규격</th>
              <th>수량(단위)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in filteredRequest" :key="index">
              <td>
                {{
                  findInfoFromId(
                    companys,
                    findInfoFromId(sales, item.sales_id).company_id,
                  ).name
                }}
              </td>
              <td>
                {{ findInfoFromId(products, item.product_id).name }}
              </td>
              <td>
                {{ item.standard }}
              </td>

              <td>
                {{
                  `${$makeComma(item.quantity)}(${
                    findInfoFromId(
                      units,
                      findInfoFromId(products, item.product_id).stock_unit_id,
                    ).name
                  })`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import {
  yyyy년MM월dd일HH시mm분ss초,
  // yyyy년MM월dd일,
  // HH시mm분,
  // X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      onlyNotOutput: false,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        try {
          let filterClone = salesClone
            .map(x => x.product_list)
            .reduce((a, b) => a.concat(b))
            .sort((a, b) => b.id - a.id)
            .filter(x => x.request_out);

          return filterClone;
        } catch (error) {
          return [];
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
    // this.curr_date = yyyy년MM월dd일(new Date());
    // this.curr_time = HH시mm분(new Date());
    // this.curr_weekDay = X요일(new Date());
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
